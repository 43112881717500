import React from "react"
import { graphql } from "gatsby"
//import gsap from "gsap"
//import ScrollTrigger from "gsap/ScrollTrigger"

import Layout from "../layouts/layout"
import SEO from "../components/seo"
import InstagramPosts from "../components/InstagramPosts"

import styled from "styled-components"

import { getNodesFromQuery } from "../service/helper"

import ArticleBlock from "../components/ArticleBlock"
import Paradise from "../components/Paradise"

import StartPageAnimation from "../components/StartpageAnimation"

// graphql query to pull data for landing page
export const query = graphql`
  fragment getImage on SanityImage {
    _key
    _type
    asset {
      _id
    }
    crop {
      top
      right
      left
      bottom
      _key
      _type
    }
    hotspot {
      height
      _type
      _key
      width
      x
      y
    }
  }
  query RootIndexQuery {
    backgroundImage: file(relativePath: { eq: "structure.png" }) {
      id
      publicURL
    }
    landingPageSection: sanityLandingPageOrder {
      items {
        title
        image {
          ...getImage
        }
        _rawContent(resolveReferences: { maxDepth: 2 })
        id
      }
    }
    siteSettings: sanitySiteSetting {
      socialMedia {
        platform
        url
      }
    }
    headerNav: sanityNavigation(_id: { eq: "headerNavigation" }) {
      id
      items {
        title
        redirect
        slug {
          current
        }
        id
      }
    }
  }
`

// styled section for landing page sections
const LandingPageSections = styled.section`
  position: relative;
  height: 100%;
`
// index page component
const IndexPage = ({ data }) => {
  const landingPageSection = data.landingPageSection
  const socialMedia = data.siteSettings?.socialMedia
  const headerNav = data.headerNav?.items
  // console.log(landingPageSection)
  return (
    <Layout showLogo={false} showHeader>
      <SEO title="Home" />
      <StartPageAnimation socialMedia={socialMedia} headerNav={headerNav} />
      <LandingPageSections>
        {/* map through landing page sections */}
        {landingPageSection?.items.map((element, idx) => {
          return (
            // return the article block from a landing page section
            <ArticleBlock
              key={element.id}
              {...element}
              idx={idx}
              total={landingPageSection?.items.length}
              className="lp-section"
            />
          )
        })}
      </LandingPageSections>
      {/* instagram post section */}
      <InstagramPosts profile="zen_studios_berlin" />
    </Layout>
  )
}

export default IndexPage
