// Landing page animations

import React, { useEffect } from "react"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { BrandLogo } from "./BrandLogo"
import Palm from "../assets/palme-1.svg"
import GummiL from "../assets/gummi-baum-left.svg"
import GummiR from "../assets/gummi-baum-right.svg"
import styled from "styled-components"
import circlePattern from "../images/structure.png"
import SocialMediaIcons from "../components/SocialMediaIcons"

// container for all animated elements
const Wrapper = styled.section`
  position: relative;
  z-index: 999;
  width: 100%;
  min-height: 80vh;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 7vh), 0 100%);
  margin-bottom: -10vh;
  background-size: max(15%, 250px) 100%;
  background-color: ${props => props.theme.background};
  background-attachment: fixed;
  background-image: repeating-linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.9)
  );
  @media (max-width: ${props => props.theme.mobile}) {
    background-size: 33% 100%;

    &:before {
      background-image: radial-gradient(
        circle,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.5)
      );
    }
  }
`
const Canvas = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 80vh;
  padding-bottom: 10vh;
  background-image:  url("${circlePattern}");
  background-size: 60px;
  background-attachment: fixed; /* this ensures the background doesn't scroll with the site */
`

// styled div container for zen studios logo + leafs in center of start page
const LogoContainer = styled.div`
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`

// styled component for start page logo, containing the imported brand logo
const StartPageLogo = styled(BrandLogo)`
  transform-origin: center center;
  height: auto;
  z-index: 1;
  @media (max-width: ${props => props.theme.mobile}) {
    margin-top: 5px;
    top: 12.5%;
    svg {
      width: 200px;
      height: auto;
    }
  }
`

const QuickJumpContainer = styled.div`
  position: relative;
  display: grid;
  column-gap: 0.2rem;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
  row-gap: 0.1rem;
  width: 100%;
  max-width: 90vw;
  justify-content: space-around;
  align-items: center;
  @media (max-width: ${props => props.theme.mobile}) {
    grid-template-columns: 1fr;
  }
`
const QuickJump = styled.button`
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.navLinkColor};
  padding: 1rem;
  border-radius: 0.25rem;
  text-align: center;
  width: 100%;
  border: 1px solid ${props => props.theme.primaryDark};
  :hover {
    border: 1px solid ${props => props.theme.primaryHover};
    color: ${props => props.theme.primaryHover};
  }
`
const SocialIconsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  padding: 0.5rem;
`

// animation
const StartpageAnimation = ({ socialMedia, headerNav }) => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    const tl1 = gsap.timeline({
      /* trigger animation */
      scrollTrigger: {
        trigger: ".anim1",
        start: "top 60px",
        end: "50%",
        scrub: true,
        markers: false,
      },
    })

    /* animate left palms */
    tl1
      .to(".left-palms", {
        duration: 1,
        xPercent: -50,
        rotation: 45,
        transformOrigin: "0% 0%",
      })
      /* animate right palms */
      .to(
        ".right-palms",
        {
          duration: 1,
          xPercent: 50,
          rotation: -45,
          transformOrigin: "100% 0%",
        },
        /* offset of total timeline to synchronize */
        "-=1"
      )
      /* animate left leaf below logo */
      .to(
        "#gummi-left",
        {
          duration: 1,
          scale: 2,
          rotation: -40,
          transformOrigin: "100% 50%",
          ease: "none",
        },
        /* offset */
        "-=1"
      )
      /* animate right leaf below logo */
      .to(
        "#gummi-right",
        {
          duration: 1,
          scale: 2,
          rotation: 40,
          transformOrigin: "0% 50%",
          ease: "none",
        },
        /* offset */
        "-=1"
      )
      /* animate header logo */
      .to(".headerLogo", { opacity: 1, duration: 2 }, "+=1")
    // .to("main", { yPercent: 100 })
  })
  return (
    <Wrapper>
      <Canvas>
        <SocialIconsContainer>
          {socialMedia ? <SocialMediaIcons accounts={socialMedia} /> : ""}
        </SocialIconsContainer>
        <LogoContainer>
          <StartPageLogo className="box" width="45vh" />
        </LogoContainer>
        <QuickJumpContainer>
          {headerNav?.map(v => (
            <a href={v.redirect}>
              <QuickJump>{v.title}</QuickJump>
            </a>
          ))}
        </QuickJumpContainer>
      </Canvas>
    </Wrapper>
  )
}

export default StartpageAnimation
